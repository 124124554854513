import React, { useState, useEffect } from 'react';

function MeldingMaken({ setActiveComponent }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    remarks: '',
    buildingObject: '',
    priority: '',
    status: 'open',
    pictures: []
  });
  const [buildings, setBuildings] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [csrfToken, setCsrfToken] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
    .then(res => res.json())
    .then(data => {
      setCsrfToken(data.csrfToken);
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  useEffect(() => {
    fetchBuildings();
    fetchPriorities();
  }, []);

  const fetchData = async (url) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}${url}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.log(response);
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  const fetchBuildings = async () => {
    const buildings = await fetchData('/buildingObjects');
    setBuildings(buildings);
  };

  const fetchPriorities = async () => {
    const priorities = await fetchData('/priorities');
    setPriorities(priorities);
  };

  const handleSubmit = async (event) => {
  event.preventDefault();

  // Maak een nieuw FormData object
  const formDataObject = new FormData();
  formDataObject.append('name', formData.name);
  formDataObject.append('description', formData.description);
  formDataObject.append('remarks', formData.remarks);
  formDataObject.append('buildingObject', formData.buildingObject);
  formDataObject.append('notifier', formData.notifier);
  formDataObject.append('priority', formData.priority);
  formDataObject.append('status', formData.status);

  // Voeg foto's toe als ze bestaan
  formData.pictures.forEach(picture => {
    if (picture.file) {
      formDataObject.append('pictures', picture.file);
    }
  });

  // Verstuur het FormData object
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/create-servicerequests`, {
      method: 'POST',
      headers: {
        'CSRF-Token': csrfToken
      },
      credentials: 'include',
      body: formDataObject  // Gebruik FormData in plaats van JSON.stringify
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    } else {
      const serviceRequest = await response.json();

      setNotification(`Uw melding met nummer: ${serviceRequest.number} is aangemaakt...`);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); // Verberg de popup na 2 seconden
        setActiveComponent('menu'); // Zet het actieve component op 'menu'
      }, 2000);
    }

  } catch (error) {
    console.error('Error creating service request:', error);
  }
};

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    // Verwerk en voeg de gekozen bestanden toe aan je state, indien nodig
    const files = Array.from(e.target.files);
    // Aanname: objectData.pictures is een array van URL's of blobs
    const updatedPictures = files.map(file => ({
      _id: URL.createObjectURL(file),  // Tijdelijke lokale URL voor voorbeeldweergave
      file  // Bewaar de File referentie voor verdere verwerking
    }));

    setFormData(prev => ({
      ...prev,
      pictures: [...prev.pictures, ...updatedPictures]
    }));
  };

  return(
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
      {showPopup && (
        <>
          <div className="overlay"></div>
          <div className="popup">{notification}</div>
        </>
      )}
      <div>
      <form className="wide-form-wrapper" onSubmit={handleSubmit}>
      <h2>Melding aanmaken</h2>
      <div className="form-group">
      <label className="form-label">Korte omschrijving melding:</label>
      <textarea
        name="name"
        className="form-input"
        value={formData.name}
        onChange={handleChange}
      />
      </div>
      <div className="form-group">
      <label className="form-label">Melding:</label>
      <textarea
        name="description"
        className="form-textarea"
        value={formData.description}
        onChange={handleChange}
      />
      </div>
      <div className="form-group">
      <label className="form-label">Contact voor uitvoering:</label>
      <textarea
        name="remarks"
        className="form-textarea"
        value={formData.remarks}
        onChange={handleChange}
      />
      </div>
      <div  className="form-group">
      <label className="form-label">Object:</label>
      <select className="form-select" name="buildingObject" value={formData.buildingObject} onChange={handleChange}>
        <option key="placeholder" value="nothing">Maak uw keuze..</option>
        {buildings.map(building => (
          <option key={building._id} value={building._id}>{building.name}</option>
        ))}
      </select>
      </div>
      <div  className="form-group">
      <label className="form-label">Prioriteit:</label>
      <select className="form-select" name="priority" value={formData.priority} onChange={handleChange}>
        <option key="placeholder" value="nothing">Maak uw keuze..</option>
        {priorities.map(priority => (
          <option key={priority._id} value={priority._id}>{priority.description}</option>
        ))}
      </select>
      </div>
      <label className="form-label">Foto's:</label>
      <div>
        {formData.pictures && formData.pictures.map(picture => (
           <div key={picture._id}>Afbeelding in afwachting van upload</div>
          )
        )}
        <input type="file" name="pictures" multiple onChange={handleFileChange} />
      </div>
      <button className="form-button" type="submit">Submit</button>
    </form>
    </div>
    </div>
  );
}

export default MeldingMaken;
