import React, { useState, useEffect } from 'react';
import { AuthProvider, useAuth } from '../authprovider.js';

const Login = () => {
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const { setIsLoggedIn, setUserRoles } = useAuth();

  // Functie om CSRF-token op te halen bij component mount
  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
      .then(res => res.json())
      .then(data => {
        setCsrfToken(data.csrfToken);
      })
      .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    const data = { userName, password, _csrf: csrfToken };    

    fetch(process.env.REACT_APP_API_BASE + '/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data),
      credentials: 'include' // Zorg dat cookies meegestuurd worden
    })
    .then(res => {
      if (!res.ok) {  // Controleert of de statuscode in de reeks 200-299 ligt
        alert("Gebruiker niet gevonden..");
        throw new Error('Network response was not ok: ' + res.statusText);
        }
        setIsLoggedIn(true);
        return res.json();  // Verwerkt de JSON alleen als de response 'ok' is
    })
    // .then(res => res.json())
    .then(data => {
      console.log('Login response:', data);
      if (data.roles) {
        setUserRoles(data.roles);
      } else {
        alert('Login failed: ' + data.message);
      }
    })
    .catch(error => {
      console.error('Login error:', error);
    });
  };

  return (
    <div className="center-horizontal-wrapper center-vertical-wrapper">
    <form onSubmit={handleLogin} className="form-wrapper">
      <div className="form-group">
        <label className="form-label">Gebruikersnaam:</label>
          <input
            type="text"
            className="form-input"
            value={userName}
            onChange={e => setUsername(e.target.value)}
          />
      </div>
      <div className="form-group">
        <label className="form-label">Wachtwoord:</label>
          <input
            type="password"
            className="form-input"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
      </div>
      <button className="form-button" type="submit">Log In</button>
    </form>
    </div>
  );
};

export default Login;
