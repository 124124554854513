import React, { useState } from 'react';
import Header from './components/header.js'
import Menu  from './components/menu.js'
import Login from './components/login.js'
import Users from './components/users.js'
import Archief from './components/archief.js'
import Geaccepteerd from './components/geaccepteerd.js'
import Geweigerd from './components/geweigerd.js'
import Meldingen from './components/meldingen.js'
import MeldingMaken from './components/meldingMaken.js'
import Objecten from './components/objecten.js'
import TechnischeGereed from './components/technischeGereed.js'
import Prioriteiten from './components/prioriteiten.js'
import Afmelden from './components/afmelden.js'
import { AuthProvider, useAuth } from './authprovider.js';

function App() {
  const { loading, isLoggedIn, userRoles } = useAuth();
  const [activeComponent, setActiveComponent] = useState('menu')

  if (loading) {
    return(
      <div>
        <div><Header /></div>
        <div>
        Loading......
        </div>
      </div>
    )
  }
  if (isLoggedIn){
    return (
      <div className="root-container">
        <div><Header /></div>
        <div>
          { activeComponent === 'menu' && <Menu setActiveComponent={setActiveComponent} />}
          { activeComponent === 'users' && <Users setActiveComponent={setActiveComponent} />}
          { activeComponent === 'meldingen' && <Meldingen setActiveComponent={setActiveComponent} />}
          { activeComponent === 'archief' && <Archief setActiveComponent={setActiveComponent} />}
          { activeComponent === 'geaccepteerd' && <Geaccepteerd setActiveComponent={setActiveComponent} />}
          { activeComponent === 'geweigerd' && <Geweigerd setActiveComponent={setActiveComponent} />}
          { activeComponent === 'meldingMaken' && <MeldingMaken setActiveComponent={setActiveComponent} />}
          { activeComponent === 'objecten' && <Objecten setActiveComponent={setActiveComponent} />}
          { activeComponent === 'technischeGereed' && <TechnischeGereed setActiveComponent={setActiveComponent} />}
          { activeComponent === 'prioriteiten' && <Prioriteiten setActiveComponent={setActiveComponent} />}
          { activeComponent === 'afmelden' && <Afmelden setActiveComponent={setActiveComponent} />}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div><Header /></div>
        <div>
          <Login />
        </div>
      </div>
    )
  }
}

const RootComponent = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default RootComponent;
