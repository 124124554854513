import React, {useEffect} from 'react';
import { AuthProvider, useAuth } from '../authprovider.js';

function Afmelden({ setActiveComponent }) {
  const { setIsLoggedIn } = useAuth();

  const fetchData = async (url) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}${url}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error('Network response was not ok.');
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const logoff = async () => {
    try {
      const logoff = await fetchData('/logoff');
      localStorage.clear();
      sessionStorage.clear();
      setIsLoggedIn(false)
      window.location.reload();
    } catch (error) {
      console.log('Error fetching roles: ', error);
    }
  }

  useEffect(() => {
    logoff();
  }, []);

  return(
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
    </div>
  );
}

export default Afmelden;
