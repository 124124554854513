import React, { useState, useEffect } from 'react';
import { useAuth } from '../authprovider.js';

function Users({ setActiveComponent }) {
  const [users, setUsers ] = useState([]);
  const [roles, setRoles] = useState([])
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchTermUsers, setSearchTermUsers] = useState('');
  const [searchTermRoles, setSearchTermRoles] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const getUsers = async() => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/users`, {
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json();
        // console.log(data);

        setUsers(data.users);
        setRoles(data.roles);
      }
    } catch (error) {
      console.log(error);
    };
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleUserSearchChange = (e) => {
    setSearchTermUsers(e.target.value);
  };

  const handleRoleSearchChange = (e) => {
    setSearchTermRoles(e.target.value);
  };

  const handleAddNewUser = () => {
    setSelectedUser({firstName: '', lastName: '', userName: '', email: '', phone: '', password: '', roles: []});
  };

  const handleAddNewRole = () => {
    setSelectedRole({name: ''})
  }

  const handleSelectUser = (user) => {
    setSelectedRole(null);
    setSelectedUser(user);
  }

  const handleSelectRole = (role) => {
    setSelectedRole(role);
    setSelectedUser(null);
  }

  const filteredUsers = users.filter(user =>
    user.firstName.toLowerCase().includes(searchTermUsers.toLowerCase()) ||
    user.lastName.toLowerCase().includes(searchTermUsers.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTermUsers.toLowerCase())
  );

  const filteredRoles = roles.filter(role =>
    role.name.toLowerCase().includes(searchTermRoles.toLowerCase())
  );

  return(
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
      <div className="split-view-container">
        <div className="list-wrapper">
          <div className="list">
            <h2>Gebruikers</h2>
            <button className="add-button" onClick={() => handleAddNewUser()}>+</button>
            <input
              type="text"
              placeholder="Zoek gebruiker..."
              value={searchTermUsers}
              onChange={handleUserSearchChange}
              className="search-input"
            />
            {filteredUsers.map(user => (
              <div key={user._id} onClick={() => handleSelectUser(user)} className={`list-item ${selectedUser && selectedUser._id === user._id ? 'active' : ''}`}>
                <p>{user.firstName} {user.lastName}</p>
              </div>
            ))}
          </div>
          <div className="list">
            <h2>Rollen</h2>
            <button className="add-button" onClick={() => handleAddNewRole()}>+</button>
            <input
              type="text"
              placeholder="Zoek rol..."
              value={searchTermRoles}
              onChange={handleRoleSearchChange}
              className="search-input"
            />
            {filteredRoles.map(role => (
              <div key={role._id} onClick={() => handleSelectRole(role)} className={`list-item ${selectedRole && selectedRole._id === role._id ? 'active' : ''}`}>
                <p>{role.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          {selectedUser && <UserForm user={selectedUser} roles={roles} refreshUsers={getUsers} />}
          {selectedRole && <RoleForm role={selectedRole} roles={roles} refreshRoles={getUsers} />}
        </div>
      </div>
    </div>
  );
}
function UserForm({ user, roles, refreshUsers }) {
  const [userData, setUserData] = useState({ ...user, password: '' });
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
    .then(res => res.json())
    .then(data => {
      setCsrfToken(data.csrfToken);
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  useEffect(() => {
  setUserData({ ...user, password: '' });
  }, [user]);


  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData._id) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(userData),
        credentials: 'include'
      });
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/update-user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(userData),
        credentials: 'include'
      });
    }
    console.log('Data to submit:', userData);
    refreshUsers(); // Refresh user list after updating
  };

  const handleRoleChange = (role) => {
    setUserData(prevUserData => {
      // Voeg rol toe of verwijder rol afhankelijk van of het checkbox aangevinkt of uitgevinkt is
      const currentRoles = prevUserData.roles || [];
      const isRolePresent = currentRoles.some(cRole => cRole._id === role._id);

      const newRoles = isRolePresent
        ? currentRoles.filter(cRole => cRole._id !== role._id)
        : [...currentRoles, role]

      return {
        ...prevUserData,
        roles: newRoles
      };
    });
  };

  return (
    <div className="user-form-container">
    <form onSubmit={handleSubmit} className="form-wrapper">
    <div className="form-group">
    <label className="form-label">
    Voornaam:
    </label>
    <input className="form-input" type="text" name="firstName" value={userData.firstName} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Achternaam:
    </label>
    <input className="form-input" type="text" name="lastName" value={userData.lastName} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Gebruikersnaam:
    </label>
    <input className="form-input" type="text" name="userName" value={userData.userName} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Email:
    </label>
    <input className="form-input" type="email" name="email" value={userData.email} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Telefoon:
    </label>
    <input className="form-input" type="text" name="phone" value={userData.phone} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Wachtwoord:
    </label>
    <input className="form-input" type="password" name="password" value={userData.password} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Rollen:
    </label>
    <div>
    {roles.map(role => (
    <div key={role._id}>
      <input
        type="checkbox"
        id={`role-${role._id}`}
        name="roles"
        value={role.name}
        checked={userData.roles.some(userRole => userRole._id === role._id)}
        onChange={() => handleRoleChange(role)}
      />
      <label htmlFor={`role-${role._id}`}>{role.name}</label>
    </div>
    ))}
    </div>
    </div>
    <button className="form-button" type="submit">{ userData._id ? "Gebruiker bijwerken" : "Gebruiker toevoegen"}</button>
    </form>
    </div>
  );
}

function RoleForm({ role, roles, refreshRoles }) {
  const [roleData, setRoleData] = useState({ ...role });
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
    .then(res => res.json())
    .then(data => {
      setCsrfToken(data.csrfToken);
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  useEffect(() => {
    setRoleData({ ...role});
  }, [role]);

  const handleInputChange = (e) => {
    setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!roleData._id) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/create-role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(roleData),
        credentials: 'include'
      });
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/update-role`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(roleData),
        credentials: 'include'
      });
    }
    console.log('Data to submit:', roleData);
    refreshRoles(); // Refresh user list after updating
  };

  const handleParentRoleChange = (role) => {
    setRoleData(prevRoleData => {
      // Controleer of de huidige parent gelijk is aan de geklikte role
      const isCurrentParent = prevRoleData.parent && prevRoleData.parent._id === role._id;

      // Als de huidige parent al de geklikte role is, verwijder het (ontkoppel parent),
      // anders stel de geklikte role in als de nieuwe parent
      const newParent = isCurrentParent ? null : role;

      return {
        ...prevRoleData,
        parent: newParent
      };
    });
  };


  const handleChildRoleChange = (role) => {
    setRoleData(prevRoleData => {
      const currentChildren = prevRoleData.children || [];
      const isRolePresent = currentChildren.some(childRole => childRole._id === role._id);

      const newChildren = isRolePresent
        ? currentChildren.filter(childRole => childRole._id !== role._id)  // Verwijder de rol
        : [...currentChildren, role];  // Voeg de rol toe

      return {
        ...prevRoleData,
        children: newChildren
      };
    });
  };


  return (
    <form onSubmit={handleSubmit} className="form-wrapper">
    <div className="form-group">
    <label className="form-label">
    Rolnaam:
    </label>
    <input className="form-input" type="text" name="name" value={roleData.name} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Subrollen:
    </label>
    <div>
    {roles.map(role => (
    <div key={role._id}>
      <input
        type="checkbox"
        id={`role-${role._id}`}
        name="roles"
        value={role.name}
        checked={roleData.children?.some(userRole => userRole.name === role.name) || false}
        onChange={() => handleChildRoleChange(role)}
      />
      <label htmlFor={`role-${role._id}`}>{role.name}</label>
    </div>
    ))}
    </div>
    </div>
    <div className="form-group">
    <label className="form-label">
    Onderdeel van:
    </label>
    <div>
    {roles.map(role => (
    <div key={role._id}>
      <input
        type="checkbox"
        id={`role-${role._id}`}
        name="roles"
        value={role.name}
        checked={roleData.parent?._id === role._id || false}
        onChange={() => handleParentRoleChange(role)}
      />
      <label htmlFor={`role-${role._id}`}>{role.name}</label>
    </div>
    ))}
    </div>
    </div>
    <button className="form-button" type="submit">Rol bijwerken</button>
    </form>
  );
}


export default Users;
