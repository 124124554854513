import React, { useState, useEffect } from 'react';

function Objecten({ setActiveComponent }) {
  const [objects, setObjects ] = useState([]);
  const [roles, setRoles] = useState([])
  const [selectedObject, setSelectedObject] = useState(null);
  const [searchTermObjects, setSearchTermObjects] = useState('');

  const getObjects = async() => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/buildingObjects`, {
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setObjects(data);
      }
    } catch (error) {
      console.log(error);
    };
  }

  const getRoles = async() => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/accessibleRoles`, {
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    };
  }

  useEffect(() => {
    getObjects();
    getRoles();
  }, []);

  const handleObjectSearchChange = (e) => {
    setSearchTermObjects(e.target.value);
  };

  const handleAddNewObject = () => {
    setSelectedObject({name: '', city: '', street: '', zipcode: '', houseNumber: '', pictures: [], roles: []});
  };

  const handleSelectObject = (object) => {
    setSelectedObject(object)
  }

  const filteredObjects = objects.filter(object =>
    object.name.toLowerCase().includes(searchTermObjects.toLowerCase())
  );

  return(
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
      <div className="split-view-container">
        <div className="list-wrapper">
          <div className="list">
            <h2>Objecten</h2>
            <button className="add-button" onClick={() => handleAddNewObject()}>+</button>
            <input
              type="text"
              placeholder="Zoek object..."
              value={searchTermObjects}
              onChange={handleObjectSearchChange}
              className="search-input"
            />
            {filteredObjects.map(object => (
              <div key={object._id} onClick={() => handleSelectObject(object)} className={`list-item ${selectedObject && selectedObject._id === object._id ? 'active' : ''}`}>
                <p>{object.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          {selectedObject && <ObjectForm Object={selectedObject} roles={roles} refreshObjects={getObjects} setSelectedObject={setSelectedObject}/>}
        </div>
      </div>
    </div>
  );
}

function ObjectForm({ Object, refreshObjects, roles, setSelectedObject }) {
  const [objectData, setObjectData] = useState({ ...Object});
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
    .then(res => res.json())
    .then(data => {
      setCsrfToken(data.csrfToken);
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  useEffect(() => {
  setObjectData({ ...Object});
  }, [Object]);

  console.log(Object.pictures);

  const handleInputChange = (e) => {
    setObjectData({ ...objectData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Voeg tekstvelden toe
    if (objectData._id){ formData.append('id', objectData._id) };
    formData.append('name', objectData.name);
    formData.append('city', objectData.city);
    formData.append('street', objectData.street);
    formData.append('houseNumber', objectData.houseNumber);
    formData.append('zipCode', objectData.zipCode);
    // Voeg rollen toe als een JSON string (als ze in array vorm zijn)
    // Voor het toevoegen van de rollen aan FormData
    objectData.roles.forEach((role, index) => {
      formData.append(`roles[${index}]`, role._id || role);  // Zorg dat role._id bestaat of stuur gewoon role
    });


    // Voeg foto's toe
    objectData.pictures.forEach(picture => {
      if (picture.file) {
        formData.append('pictures', picture.file);
      }
    });

    const response = await fetch(`${process.env.REACT_APP_API_BASE}/${objectData._id ? 'update-buildingobject' : 'create-buildingobject'}`, {
      method: objectData._id ? 'PUT' : 'POST',
      headers: {
        // Geen 'Content-Type' header nodig; de browser zal dit invullen met de juiste boundary.
        'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
      },
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      console.log('Object succesvol bijgewerkt of aangemaakt');
      const data = await response.json();
      refreshObjects(); // Refresh de objectenlijst na de update
      setSelectedObject(data);
    } else {
      console.error('Fout bij het versturen van het formulier:', await response.text());
    }
  };

  const handleDeletePicture = async (pictureId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/BuildingObjectPictureDelete/${pictureId}`, {
        method: 'DELETE',
        headers: {
          // Geen 'Content-Type' header nodig; de browser zal dit invullen met de juiste boundary.
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        credentials: 'include', // Afhankelijk van je auth setup
      });

      if (response.ok) {
        console.log('Picture deleted successfully');
        // Filter de verwijderde foto uit de local state
        setObjectData(prevData => ({
          ...prevData,
          pictures: prevData.pictures.filter(picture => picture._id !== pictureId)
        }));
      } else {
        throw new Error('Failed to delete the picture');
      }
    } catch (error) {
      console.error('Error deleting picture:', error);
    }
  };

  const handleRoleChange = (role) => {
    setObjectData(prevObjectData => {
      // Voeg rol toe of verwijder rol afhankelijk van of het checkbox aangevinkt of uitgevinkt is
      const currentRoles = prevObjectData.roles || [];
      const isRolePresent = currentRoles.some(cRole => cRole._id === role._id);

      const newRoles = isRolePresent
        ? currentRoles.filter(cRole => cRole._id !== role._id)
        : [...currentRoles, role]

      return {
        ...prevObjectData,
        roles: newRoles
      };
    });
  };

  const handleFileChange = (e) => {
    // Verwerk en voeg de gekozen bestanden toe aan je state, indien nodig
    const files = Array.from(e.target.files);
    // Aanname: objectData.pictures is een array van URL's of blobs
    const updatedPictures = files.map(file => ({
      _id: URL.createObjectURL(file),  // Tijdelijke lokale URL voor voorbeeldweergave
      file  // Bewaar de File referentie voor verdere verwerking
    }));

    setObjectData(prev => ({
      ...prev,
      pictures: [...prev.pictures, ...updatedPictures]
    }));
  };

  return (
    <div className="Object-form-container">
    <form onSubmit={handleSubmit} className="form-wrapper">
    <div className="form-group">
    <label className="form-label">
    Objectnaam:
    </label>
    <input className="form-input" type="text" name="name" value={objectData.name} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Plaats:
    </label>
    <input className="form-input" type="text" name="city" value={objectData.city} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Straat:
    </label>
    <input className="form-input" type="text" name="street" value={objectData.street} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Huisnummer:
    </label>
    <input className="form-input" type="text" name="houseNumber" value={objectData.houseNumber} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Postcode:
    </label>
    <input className="form-input" type="text" name="zipCode" value={objectData.zipCode} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Rollen met toegang:
    </label>
    <div>
    {roles.map(role => (
    <div key={role._id}>
      <input
        type="checkbox"
        id={`role-${role._id}`}
        name="roles"
        value={role.name}
        checked={objectData.roles.some(objectRole => objectRole._id === role._id)}
        onChange={() => handleRoleChange(role)}
      />
      <label htmlFor={`role-${role._id}`}>{role.name}</label>
    </div>
    ))}
    </div>
    <div className="form-group">
  <label className="form-label">Foto's:</label>
  <div>
    {objectData.pictures && objectData.pictures.map(picture => (
      picture.location ? (
        <div>
        <img key={picture._id} src={`${process.env.REACT_APP_API_BASE}/images/${picture.location.split('/').pop()}`} alt="Uploaded" style={{ width: '200px', height: '200px' }} />
        <button className="form-button" onClick={() => handleDeletePicture(picture._id)} style={{ marginLeft: '10px' }}>Verwijderen</button>
        </div>
      ) : (
        <div key={picture._id}>Afbeelding in afwachting van upload</div>
      )
    )
    )}
    <input type="file" name="pictures" multiple onChange={handleFileChange} />
  </div>
  </div>
    </div>
    <button className="form-button" type="submit">{ objectData._id ? "Object bijwerken" : "Object toevoegen"}</button>
    </form>
    </div>
  );
}

export default Objecten;
