import React from 'react';
import { useAuth } from '../authprovider.js';

function Menu({ setActiveComponent }) {
  const {isLoggedIn, userRoles, checkLoginStatus} = useAuth();

  const handleMenuClick = async (componentName) => {
    await checkLoginStatus(); // Valideer de login status opnieuw
    setActiveComponent(componentName);
  }

  if (userRoles.length > 0) {
    const menuItems = [
      { name: "Melding Maken", isPublic: true, roles: [], onClick: () => handleMenuClick('meldingMaken') },
      { name: "Meldingen", roles: [], onClick: () => handleMenuClick('meldingen') },
      { name: "Geaccepteerd", roles: ["Global Admin", "Bouwbedrijf Doornenbal", "Gemeente Ooststellingwerf"], onClick: () => handleMenuClick('geaccepteerd') },
      { name: "Geweigerd", roles: ["Global Admin", "Bouwbedrijf Doornenbal", "Gemeente Ooststellingwerf"], onClick: () =>handleMenuClick('geweigerd') },
      { name: "Technisch gereed", roles: [], onClick: () => handleMenuClick('technischeGereed') },
      { name: "Archief", roles: ["Global Admin", "Bouwbedrijf Doornenbal", "Gemeente Ooststellingwerf"], onClick: () => handleMenuClick('archief') },
      { name: "Objecten", roles: ["Global Admin", "Bouwbedrijf Doornenbal"], onClick: () => handleMenuClick('objecten') },
      { name: "Gebruikers en Rollen", roles: ["Global Admin", "Bouwbedrijf Doornenbal"], onClick: () => handleMenuClick('users') },
      { name: "Prioriteiten", roles: ["Global Admin", "Bouwbedrijf Doornenbal"], onClick: () => handleMenuClick('prioriteiten') },
      { name: "Uitloggen", isPublic: true, roles: [], onClick: () => handleMenuClick('afmelden') },
    ];

    const visibleTiles = menuItems.filter(item =>
      item.roles.length === 0 || item.isPublic || item.roles.some(role => userRoles.includes(role))
    );

    return (
      <div className="App">
        <div className="center-horizontal-wrapper">
          <div className="menu-container">
            {isLoggedIn && visibleTiles.map(tile => (
              <div key={tile.name} className="menu-tile" onClick={tile.onClick}>
                {tile.name}
              </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
