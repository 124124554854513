import React, { useState, useEffect, useRef } from 'react';

function Meldingen({ setActiveComponent }) {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [sortField, setSortField] = useState('number');
  const [sortDirection, setSortDirection] = useState('asc');
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [filters, setFilters] = useState({
    number: '',
    'userData.userName': '',
    'buildingObjectData.name': '',
    'priorityData.description': '',
    created: '',
    status: '',
    name: ''
  });

  useEffect(() => {
    fetchServiceRequests();
    fetchRoles();
    sortServiceRequests(sortField);
  }, []);

  const fetchData = async (url) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}${url}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error('Network response was not ok.');
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const fetchRoles = async () => {
    try {
      const accessibleRoles = await fetchData('/accessibleRoles');
      setRoles(accessibleRoles);
    } catch (error) {
      console.log('Error fetching roles: ', error);
    }
  }

  const fetchServiceRequests = async () => {
    try {
      const declinedServiceRequests = await fetchData('/serviceRequests/declined');
      setServiceRequests([...declinedServiceRequests]);
    } catch (error) {
      console.error('Error fetching service requests:', error);
    }
  };

  const hasRequiredRole = roles.some(role => role.name === 'Global Admin' || role.name === 'Bouwbedrijf Doornenbal');

  const sortServiceRequests = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    const sortedRequests = [...serviceRequests];
    sortedRequests.sort((a, b) => {
      const fieldA = getFieldByPath(a, field);
      const fieldB = getFieldByPath(b, field);
      if (direction === 'asc') {
        return fieldA > fieldB ? 1 : -1;
      } else {
        return fieldA < fieldB ? 1 : -1;
      }
    });
    setServiceRequests(sortedRequests);
  };

  const getFieldByPath = (object, path) => {
    const keys = path.split('.');
    let value = object;
    for (let key of keys) {
      value = value[key];
    }
    return value;
  };

  const toggleExpandedRow = (rowId) => {
    setExpandedRowId(rowId === expandedRowId ? null : rowId);
  };

  const handleFilterChange = (e, field) => {
    const { value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value
    }));
  };

  const filteredServiceRequests = serviceRequests.filter(request => {
    return Object.keys(filters).every(field => {
      const value = getFieldByPath(request, field).toString().toLowerCase();
      return value.includes(filters[field].toLowerCase());
    });
  });

  return (
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
      <div className="wide-form-wrapper">
        <h2>Meldingen Overzicht</h2>
        <table className="service-requests-table">
          <thead>
            <tr>
              <SortableHeader
                field="number"
                label="Nummer"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="name"
                label="Melding"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="userData.userName"
                label="Melder"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="buildingObjectData.name"
                label="Object"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="priorityData.description"
                label="Prioriteit"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="created"
                label="Aangemaakt"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              <SortableHeader
                field="status"
                label="Status"
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={sortServiceRequests}
              />
              {hasRequiredRole ? (
                <th>Acties</th>
              ) : (<></>)}
            </tr>
            <tr>
              {['number', 'name', 'userData.userName', 'buildingObjectData.name', 'priorityData.description', 'created', 'status'].map(field => (
                <th key={field}>
                  <input
                    type="text"
                    placeholder={`Zoeken`}
                    value={filters[field]}
                    onChange={e => handleFilterChange(e, field)}
                  />
                </th>
              ))}
              {hasRequiredRole ? (
                <th></th>
              ) : (<></>)}
            </tr>
          </thead>
          <tbody>
            {filteredServiceRequests.map(request => (
              <ServiceRequestRow
                key={request._id}
                fetchData={fetchData}
                serviceRequests={serviceRequests}
                setServiceRequests={setServiceRequests}
                request={request}
                hasRequiredRole={hasRequiredRole}
                expanded={request._id === expandedRowId}
                toggleExpandedRow={toggleExpandedRow}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function SortableHeader({ field, label, sortField, sortDirection, onSort }) {
  const isSorted = field === sortField;
  const arrowSymbol = sortDirection === 'asc' ? '▲' : '▼';
  return (
    <th onClick={() => onSort(field)}>
      {label} {isSorted && arrowSymbol}
    </th>
  );
}

function ServiceRequestRow({ request, hasRequiredRole, expanded, toggleExpandedRow, fetchData, serviceRequests, setServiceRequests }) {
  const toggleExpanded = () => {
    toggleExpandedRow(request._id);
  };

  const postData = async (url, body, csrfToken) => {
    try {
      console.log(csrfToken);
      const response = await fetch(`${process.env.REACT_APP_API_BASE}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.csrfToken
        },
        credentials: 'include',
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error('Network response was not ok.');
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const approveRequest = async (request, e) => {
    e.stopPropagation();
    console.log(request._id);
    const csrfToken = await fetchData('/csrf-token');
    const acceptServiceRequest = await postData('/acceptServiceRequest/' + request._id, {}, csrfToken);
    console.log(acceptServiceRequest);
    setServiceRequests(prevRequests => prevRequests.map(req =>
      req._id === request._id ? { ...req, status: 'accepted' } : req
    ));
  };

  const declineRequest = async (request, e) => {
    e.stopPropagation();
    const csrfToken = await fetchData('/csrf-token');
    const denyServiceRequest = await postData('/denyServiceRequest/' + request._id, {}, csrfToken);
    console.log(denyServiceRequest);
    setServiceRequests(prevRequests => prevRequests.map(req =>
      req._id === request._id ? { ...req, status: 'declined' } : req
    ));
  };

  const markReady = async (request, e) => {
    e.stopPropagation();
    const csrfToken = await fetchData('/csrf-token');
    const readyServiceRequest = await postData('/readyServiceRequest/' + request._id, {}, csrfToken);
    console.log(readyServiceRequest);
    setServiceRequests(prevRequests => prevRequests.map(req =>
      req._id === request._id ? { ...req, status: 'ready' } : req
    ));
  };

  const completeRequest = async (request, e) => {
    e.stopPropagation();
    const csrfToken = await fetchData('/csrf-token');
    const completeServiceRequest = await postData('/completeServiceRequest/' + request._id, {}, csrfToken);
    console.log(completeServiceRequest);
    setServiceRequests(prevRequests => prevRequests.map(req =>
      req._id === request._id ? { ...req, status: 'completed' } : req
    ));
  };

  const renderButtons = (status, request, approveRequest, declineRequest, markReady, completeRequest) => {
    switch (status) {
      case 'open':
        return (
          <>
            <button className="form-button spaced-button" onClick={(e) => approveRequest(request, e)}>Goedkeuren</button>
            <button className="form-button spaced-button" onClick={(e) => declineRequest(request, e)}>Afkeuren</button>
          </>
        );
      case 'accepted':
        return (
          <>
            <button className="form-button spaced-button" onClick={(e) => declineRequest(request, e)}>Afkeuren</button>
            <button className="form-button spaced-button" onClick={(e) => markReady(request, e)}>Gereedmelden</button>
          </>
        );
      case 'ready':
        return (
          <button className="form-button spaced-button" onClick={(e) => completeRequest(request, e)}>Voltooien</button>
        );
      case 'declined':
        return (
          <>
            <button className="form-button spaced-button" onClick={(e) => approveRequest(request, e)}>Goedkeuren</button>
            <button className="form-button spaced-button" onClick={(e) => completeRequest(request, e)}>Voltooien</button>
          </>
        );
      default:
        return null;
    }
  };

  const statusTranslations = {
    open: 'Open',
    accepted: 'Geaccepteerd',
    ready: 'Technische Gereed',
    declined: 'Afgekeurd',
    completed: 'Voltooid',
  };

  return (
    <>
      <tr onClick={toggleExpanded} className={expanded ? 'expanded-row' : ''}>
        <td>{request.formattedNumber}</td>
        <td>{request.name}</td>
        <td>{request.userData.userName}</td>
        <td>{request.buildingObjectData.name}</td>
        <td>{request.priorityData.description}</td>
        <td>{new Date(request.created).toLocaleString('nl-NL')}</td>
        <td>{statusTranslations[request.status]}</td>
        {hasRequiredRole ? (
          <td>
            {renderButtons(request.status, request, approveRequest, declineRequest, markReady, completeRequest)}
          </td>
        ) : null}
      </tr>
      {expanded && (
        <tr className="expanded-row-content">
          <td colSpan={hasRequiredRole ? "7" : "6" }>
            <ExpandedRow request={request} />
          </td>
        </tr>
      )}
    </>
  );
}

function ExpandedRow({ request }) {
  const [photos, setPhotos] = useState([]);
  const [workOrder, setWorkOrder] = useState(null);

  const fetchPhotos = async (pictureData) => {
    try {
      const photoData = await Promise.all(
        pictureData.map(async (picture) => {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE}/images/${picture.location.split('/').pop()}`,
            {
              credentials: 'include',
            }
          );
          if (response.ok) {
            return URL.createObjectURL(await response.blob());
          } else {
            throw new Error('Network response was not ok.');
          }
        })
      );
      setPhotos(photoData);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const fetchWorkOrder = async (request) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/workOrders/${request.number}`,
        {
          credentials: 'include',
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setWorkOrder(url);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching work order:', error);
    }
  };

  useEffect(() => {
    fetchPhotos(request.pictureData, setPhotos);
  }, [request.pictureData]);

  useEffect(() => {
    fetchWorkOrder(request);
  }, [request.number]);

  return (
    <div className="row">
      <div className="col-9">
        {workOrder ? (
          <object
            data={workOrder}
            type="application/pdf"
            width="100%"
            height="800px"
          >
            <p>Werkbon weergeven...</p>
          </object>
        ) : (
          <p>Werkbon ophalen...</p>
        )}
      </div>
    </div>
  );
}

export default Meldingen;
