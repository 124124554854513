import React from 'react';


// <div className="banner-text">
//     <p>Serviceportaal</p>
// </div>

function Header() {
  return(
    <div className="banner">
      <img src="logo.png" alt="Bouwbedrijf Doornenbal" className="banner-logo" />
      <div className="banner-text">
          <h1>| SERVICEPORTAAL</h1>
      </div>
    </div>
  );
}

export default Header;
