import React, { useState, useEffect } from 'react';

function Prioriteiten({ setActiveComponent }) {
  const [objects, setObjects ] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [searchTermObjects, setSearchTermObjects] = useState('');

  const getObjects = async() => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/priorities`, {
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json();
        console.log(data);

        setObjects(data);
      }
    } catch (error) {
      console.log(error);
    };
  }

  useEffect(() => {
    getObjects();
  }, []);

  const handleObjectSearchChange = (e) => {
    setSearchTermObjects(e.target.value);
  };

  const handleAddNewObject = () => {
    setSelectedObject({description: '', units: 'dagen', count: '', remarks: ''});
  };

  const handleSelectObject = (object) => {
    setSelectedObject(object)
  }

  const filteredObjects = objects.filter(object =>
    object.description.toLowerCase().includes(searchTermObjects.toLowerCase())
  );

  return(
    <div className="App">
      <button className="close-button" onClick={() => setActiveComponent('menu')}>
        <span className="close-icon" aria-hidden="true"></span>
      </button>
      <div className="split-view-container">
        <div className="list-wrapper">
          <div className="list">
            <h2>Prioriteiten</h2>
            <button className="add-button" onClick={() => handleAddNewObject()}>+</button>
            <input
              type="text"
              placeholder="Zoek prioriteit..."
              value={searchTermObjects}
              onChange={handleObjectSearchChange}
              className="search-input"
            />
            {filteredObjects.map(object => (
              <div key={object._id} onClick={() => handleSelectObject(object)} className={`list-item ${selectedObject && selectedObject._id === object._id ? 'active' : ''}`}>
                <p>{object.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          {selectedObject && <PriorityForm Object={selectedObject} refreshObjects={getObjects} setSelectedObject={setSelectedObject}/>}
        </div>
      </div>
    </div>
  );
}

function PriorityForm({ Object, refreshObjects, roles, setSelectedObject }) {
  const [objectData, setObjectData] = useState({ ...Object});
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + '/csrf-token', { credentials: 'include' }) // Zorg dat cookies meegestuurd worden
    .then(res => res.json())
    .then(data => {
      setCsrfToken(data.csrfToken);
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  useEffect(() => {
  setObjectData({ ...Object});
  }, [Object]);

  const handleInputChange = (e) => {
    setObjectData({ ...objectData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!objectData._id) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/create-priority`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(objectData),
        credentials: 'include'
      });
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/update-priority`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Zorg dat dit overeenkomt met je serververwachtingen
        },
        body: JSON.stringify(objectData),
        credentials: 'include'
      });
    }
    refreshObjects(); // Refresh user list after updating
  };

  return (
    <div className="Object-form-container">
    <form onSubmit={handleSubmit} className="form-wrapper">
    <div className="form-group">
    <label className="form-label">
    Omschrijving:
    </label>
    <input className="form-input" type="text" name="description" value={objectData.description} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Eenheden:
    </label>
    <select name="units" value={objectData.units} onChange={handleInputChange}>
      <option key="uren" value="uren">Uren</option>
      <option key="dagen" value="dagen">Dagen</option>
      <option key="werkdagaen" value="werkdagen">Werkdagen</option>
      <option key="weken" value="weken">Weken</option>
      <option key="maanden" value="maanden">Maanden</option>
    </select>
    </div>
    <div className="form-group">
    <label className="form-label">
    Aantal:
    </label>
    <input className="form-input" type="text" name="count" value={objectData.count} onChange={handleInputChange} />
    </div>
    <div className="form-group">
    <label className="form-label">
    Opmerkingen:
    </label>
    <input className="form-input" type="text" name="remarks" value={objectData.remarks} onChange={handleInputChange} />
    </div>
    <button className="form-button" type="submit">{ objectData._id ? "Prioriteit bijwerken" : "Prioriteit toevoegen"}</button>
    </form>
    </div>
  );
}

export default Prioriteiten;
